import React from "react"
import PageHeader from "../components/common/PageHeader"
import Subscribe from "../components/financial/Subscribe"
import Layout from "../components/layout"
import PressReleases from "../components/news/PressReleases"
import { useStaticQuery, graphql } from "gatsby"
import HeaderFeaturedImage from "../components/common/HeaderFeaturedImage"
import SEO from "../components/seo"
export default function NewsOPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 30 }) {
        id
        title
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  const { title, featuredImage, content } = data.wpPage

  return (
    <Layout>
      <SEO title={title} />
      <div>
        {featuredImage && (
          <HeaderFeaturedImage featuredImage={featuredImage} title={title} />
        )}
        <div className="max-w-6xl mx-auto py-8 lg:py-16 px-4 lg:px-8">
          {!featuredImage ? <PageHeader text={title} /> : null}
          <Subscribe />
          <PressReleases />
        </div>
      </div>
    </Layout>
  )
}
