import React from "react"
import Select from "react-select"
import { useState, useEffect } from "react"
import Link from "../common/Link"

import moment from "moment"
import Button from "../common/Button"

export default function PressReleases(props) {
  const [selectedOption, setSelectedOption] = useState("posts")
  const options = [
    { value: "", label: "All items" },
    { value: "tag=:regulatory", label: "Regulatory" },
    { value: "tag=sub:report", label: "Financial Reports" },

    {
      value:
        "filter%3D%28not%28.properties.tags%40%3E%5B%22%3Aregulatory%22%5D%29%29",
      label: "Other News",
    },
  ]
  const langOptions = [
    { value: "lang=en", label: "English" },
    { value: "lang=sv", label: "Swedish" },
  ]
  const [language, setLanguage] = useState(langOptions[0])

  const [mfnItem, setMfnItem] = useState(null)

  useEffect(() => {
    // get data from GitHub api
    fetch(
      `https://feed.mfn.se/v1/feed/708ba9c1-44c4-45b2-bde1-e4cf873ac86e.json?${selectedOption.value}&${language.value}`
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setMfnItem(resultData)
      }) // set data for the number of stars
  }, [selectedOption, language])

  return (
    <div className="max-w-3xl mx-auto">
      <div className="flex flex-colm lg:flex-row gap-4">
        <Select
          onChange={setSelectedOption}
          options={options}
          className="flex-1"
          placeholder="All items"
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: `rgba(15,163,194, .25)`,
              primary75: `rgba(15,163,194, .75)`,
              primary50: `rgba(15,163,194, .5)`,
              primary: `#0fa3c2`,
            },
          })}
        />
        <Select
          onChange={setLanguage}
          options={langOptions}
          className="flex-1"
          placeholder={language.label}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: `rgba(15,163,194, .25)`,
              primary75: `rgba(15,163,194, .75)`,
              primary50: `rgba(15,163,194, .5)`,
              primary: `#0fa3c2`,
            },
          })}
        />
      </div>
      {mfnItem?.items.length > 0 ? (
        <div>
          {" "}
          {mfnItem?.items.map(post => {
            return <NewsItem key={post.news_id} post={post} />
          })}
        </div>
      ) : (
        <div className="py-8">No items found</div>
      )}
    </div>
  )
}

function NewsItem({ post }) {
  const { title, slug, html: content, news_id } = post.content
  moment().format()

  const date = moment(post.content.publish_date).format("YYYY-MM-DD")
  return (
    <article className="w-full flex flex-col gap-4 text-xl  py-8 border-b border-primary/20 last:border-none">
      <header className="flex flex-col gap-4">
        <div className="text-secondary">{date}</div>
        <h3 className="font-bold text-primary text-2xl tracking-tight">
          <Link to={`/news/${post.news_id}`} className="">
            {" "}
            {title}
          </Link>
        </h3>
      </header>
      {/* <div>
        <div className="mb-0 pb-0 text-md md:text-base ">
          <div
            dangerouslySetInnerHTML={{
              __html: content.substring(0, 300) + "...",
            }}
            className="mfn-content"
          /> 
           <div className="py-8">
            <Button text="Read more" url={`/app/newsitem/${slug}`} />
          </div> 
        </div>
      </div> */}
    </article>
  )
}
