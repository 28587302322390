import React, { useState, useEffect } from "react"

export default function Subscribe() {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      ;(function (widgets) {
        var url = "https://widget.datablocks.se/api/rose"
        if (!window._MF) {
          var b = document.createElement("script")
          b.type = "text/javascript"
          b.async = true
          b.src = url + "/assets/js/loader-v4.js"
          document.getElementsByTagName("body")[0].appendChild(b)
        }
        window._MF = window._MF || {
          data: [],
          url: url,
          ready: !!0,
          render: function () {
            window._MF.ready = !0
          },
          push: function (w) {
            window._MF.data.push(w)
          },
        }
        window._MF.push(widgets)
      })([
        {
          query: "#subscribe",
          widget: "subscribe-v2",
          locale: "en",
          token: "03a343e8-93d1-4422-8ad2-858183a49441",
        },
      ])
    }
  }, [])

  return (
    <div className="">
      <div className="mfn-widget" id="stock-table">
        <div className="max-w-3xl text-base  mx-auto">
          <div id="subscribe" className="py-8 border-primary border-b-2 mb-8" />
        </div>
      </div>
    </div>
  )
}
